import { useEffect } from "react"
import useDownloadApp from "model/users/invite/useDownloadApp"
import { isAndroid, isIOS } from "react-device-detect"
import { styled, Typography } from "@mui/material"
import AppDownloadBadges from "components/views/generic/AppDownloadBadges"
import ProbuildLogoBadge from "components/views/generic/ProbuildLogoBadge"

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
})

const StyledTypography = styled(Typography)({
  textAlign: "center",
})

const DownloadAppPage = () => {
  const downloadApp = useDownloadApp()
  useEffect(() => {
    if (downloadApp) {
      if (isAndroid) {
        window.location.href = downloadApp.androidDownloadUrl
      }
      if (isIOS) {
        window.location.href = downloadApp.iOSDownloadUrl
      }
    }
  }, [downloadApp])
  if (!downloadApp) return null
  return (
    <RootDiv>
      <ProbuildLogoBadge />
      <StyledTypography variant="h4">
        {downloadApp.callToActionText}
      </StyledTypography>
      <AppDownloadBadges />
    </RootDiv>
  )
}

export default DownloadAppPage
