import { Button } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { ArrowForward } from "@mui/icons-material"
import AppDownloadBadges from "./AppDownloadBadges"
import usePlatform, { Platform } from "model/utils/usePlatform"
import ProbuildLogoBadge from "./ProbuildLogoBadge"

const RootDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
})

const IgnoreSuggestionContainerDiv = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  marginTop: theme.spacing(8),
}))

const InstallSuggestionView = ({
  ignoreSuggestion,
}: {
  ignoreSuggestion: () => void
}) => {
  const platform = usePlatform()
  if (!platform) return null
  return (
    <RootDiv>
      <ProbuildLogoBadge />
      <Typography sx={{ mr: 1, textAlign: "center", fontSize: "large" }}>
        For the best experience, download the Probuild app on the{" "}
        {storeName(platform)}, or use your computer to access this website.
      </Typography>
      <AppDownloadBadges />
      <IgnoreSuggestionContainerDiv>
        <Button
          sx={{ textTransform: "none" }}
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => ignoreSuggestion()}
        >
          <Typography sx={{ mr: 1 }}>Sign in anyway</Typography>
          <ArrowForward fontSize={"large"} />
        </Button>
      </IgnoreSuggestionContainerDiv>
    </RootDiv>
  )
}

const storeName = (type: Platform): string => {
  switch (type) {
    case "iOS":
      return "App Store"
    case "Android":
      return "Play Store"
  }
}

export default InstallSuggestionView
