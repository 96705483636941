import { isAndroid, isIOS } from "react-device-detect"

type Platform = "iOS" | "Android"

function usePlatform(): Platform | null {
  return isIOS ? "iOS" : isAndroid ? "Android" : null
}

export default usePlatform
export type { Platform }
