import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom"
import { CssBaseline } from "@mui/material"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import "typeface-roboto"
import DependencyProviderProvider from "contexts/providers/DependencyProviderProvider"
import SnackbarProvider from "contexts/providers/SnackbarProvider"
import UserProvider from "contexts/providers/UserProvider"
import NotFoundPage from "./pages/NotFoundPage"
import AuthRoutes from "./routing/AuthRoutes"
import SupportAgentRoutes from "./routing/SupportAgentRoutes"
import TeamRoutes from "./routing/TeamRoutes"
import useAccountsList from "model/app/useAccountsList"
import paths from "model/utils/paths"
import SnackbarAlert from "components/views/SnackbarAlert"
import { useEffect } from "react"

import * as shared from "probuild-shared"
import AppTheme from "./AppTheme"
import DownloadAppPage from "./pages/DownloadAppPage"

class LoggerApi {
  getLevel() {
    return shared.com.probuildsoftware.probuild.library.common.LibraryLogger
      .Companion.LOGGER_INFO
  }
  onDebug(message: string) {
    console.debug(message)
  }
  onError(message: string, throwable: any) {
    console.error(message)
    if (throwable) {
      console.error(throwable)
    }
  }
  onInfo(message: string) {
    console.info(message)
  }
}

const App = () => {
  useEffect(() => {
    shared.com.probuildsoftware.probuild.library.common.LibraryLogger.Companion.registerLogger(
      new LoggerApi()
    )
  })
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline />
        <RouterProvider
          router={createBrowserRouter(
            createRoutesFromElements(
              <>
                <Route path="/*" element={<WebPortalRootRoute />}>
                  <Route index element={<WebPortalIndexRoute />} />
                  <Route path="auth/*" element={<AuthRoutes />} />
                  <Route path="agent/*" element={<SupportAgentRoutes />} />
                  <Route path="links/:teamKey" element={<DownloadAppPage />} />
                  <Route path="teams/:teamKey/*" element={<TeamRoutes />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </>
            )
          )}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const WebPortalRootRoute = () => {
  return (
    <SnackbarProvider>
      <UserProvider>
        <DependencyProviderProvider>
          <Outlet />
          <SnackbarAlert />
        </DependencyProviderProvider>
      </UserProvider>
    </SnackbarProvider>
  )
}

const WebPortalIndexRoute = () => {
  const [accounts] = useAccountsList()
  const currentAccountTeamKey = accounts?.currentAccount?.teamKey
  if (!accounts) {
    return <></>
  } else if (accounts?.isSupportAgent === true) {
    return <Navigate to={paths.supportAgentFeed()} replace={true} />
  } else if (currentAccountTeamKey) {
    return <Navigate to={paths.team(currentAccountTeamKey)} replace={true} />
  } else {
    return <Navigate to={paths.authPhone()} replace={true} />
  }
}

export default App
