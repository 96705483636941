import React, { useState, useEffect, useContext } from "react"

import * as shared from "probuild-shared"

import UserContext from "contexts/UserContext"
import DependencyProviderContext from "contexts/DependencyProviderContext"
import LiveFileApi from "model/database/LiveFileApi"
import LiveDataApi from "model/database/LiveDataApi"
import LivePlatformLocaleApi from "model/locale/LivePlatformLocaleApi"
import LivePlatformTimeZoneApi from "model/timezone/LivePlatformTimeZoneApi"
import LiveKeyValueStorageApi from "model/persistence/LiveKeyValueStorageApi"
import LiveRsaCryptoApi from "model/crypto/LiveRsaCryptoApi"
import LiveAesSecretStorageApi from "model/crypto/LiveAesSecretStorageApi"
import LiveAnalyticsApi from "model/analytics/LiveAnalyticsApi"
import LiveFirestoreApi from "model/database/LiveFirestoreApi"
import LiveFirebaseAuthApi from "model/auth/LiveFirebaseAuthApi"
import LiveRemoteConfigApi from "model/config/LiveRemoteConfigApi"
import LivePlatformMetadataApi from "model/metadata/LivePlatformMetadataApi"
import LivePhoneNumbersApi from "model/phone/LivePhoneNumbersApi"
import LiveEnvironmentApi from "model/environment/LiveEnvironmentApi"
import LiveLegacyAuthApi from "model/auth/LiveLegacyAuthApi"
import LiveConnectivityApi from "model/connectivity/LiveConnectivityApi"
import LiveAppCheckApi from "model/appcheck/LiveAppCheckApi"

const DependencyProviderProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [dependencyProvider, setDependencyProvider] =
    useState<shared.com.probuildsoftware.probuild.library.common.model.dependencies.DependencyProvider | null>(
      null
    )
  const user = useContext(UserContext)
  const isSupportAgent = user.signedInUser?.isSupportAgent() === true
  const authClaims = user?.signedInUser?.claims
  useEffect(() => {
    if (!user.checked) return
    const dependencyProvider = createDependencyProvider(
      isSupportAgent,
      authClaims
    )
    setDependencyProvider(dependencyProvider)
  }, [isSupportAgent, user.checked, authClaims])
  return (
    <DependencyProviderContext.Provider value={dependencyProvider}>
      {children}
    </DependencyProviderContext.Provider>
  )
}

const createDependencyProvider = (
  isSupportAgent: boolean,
  authClaims: { [key: string]: any } | undefined
): shared.com.probuildsoftware.probuild.library.common.model.dependencies.DependencyProvider => {
  return {
    dataApi: new LiveDataApi(),
    fileApi: new LiveFileApi(),
    platformLocaleApi: new LivePlatformLocaleApi(),
    platformTimeZoneApi: new LivePlatformTimeZoneApi(),
    aesCryptoApi:
      new shared.com.probuildsoftware.probuild.library.common.model.crypto.SharedAesCryptoApi(),
    aesSecretStorageApi: createAesSecretStorageApi(isSupportAgent),
    analyticsApi: new LiveAnalyticsApi(),
    keyValueStorageApi: new LiveKeyValueStorageApi(),
    rsaCryptoApi: createRsaCryptoApi(),
    firestoreApi: new LiveFirestoreApi(),
    firebaseAuthApi: new LiveFirebaseAuthApi(),
    remoteConfigApi: new LiveRemoteConfigApi(),
    platformMetadataApi: new LivePlatformMetadataApi(),
    phoneNumbersApi: new LivePhoneNumbersApi(),
    environmentApi: createEnvironmentApi(),
    legacyAuthApi: new LiveLegacyAuthApi(authClaims),
    connectivityApi: new LiveConnectivityApi(),
    appCheckApi: new LiveAppCheckApi(),
  }
}

const createAesSecretStorageApi = (isSupportAgent: boolean) => {
  if (isSupportAgent) {
    console.log("Support agent aes secret storage api in use")
    return new shared.com.probuildsoftware.probuild.library.common.model.crypto.SupportAgentAesSecretStorageApi(
      new LiveFirebaseAuthApi(),
      createEnvironmentApi()
    )
  } else {
    console.log("Live aes secret storage api in use")
    return new LiveAesSecretStorageApi()
  }
}

const createRsaCryptoApi = () => {
  return new LiveRsaCryptoApi()
}

const createEnvironmentApi = () => {
  return new LiveEnvironmentApi()
}

export default DependencyProviderProvider
