import { styled } from "@mui/material/styles"

const ProbuildLogoContainerDiv = styled("div")(({ theme }) => ({
  maxHeight: "30vh",
  maxWidth: "30vh",
  width: "60vw",
  height: "60vw",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "10%",
  margin: theme.spacing(4),
}))

const ProbuildLogoImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  padding: "5%",
})

const ProbuildLogoBadge = () => {
  return (
    <ProbuildLogoContainerDiv>
      <ProbuildLogoImage src="/logoTransparent.png" alt="Probuild" />
    </ProbuildLogoContainerDiv>
  )
}

export default ProbuildLogoBadge
