import { styled } from "@mui/material/styles"
import { Box, ListItemButton, Typography } from "@mui/material"

import * as shared from "probuild-shared"

import UserAvatar from "../users/UserAvatar"

const TextContainerDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
}))

const TableCellContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: theme.spacing(1),
}))

const TimesheetListItemView = ({
  teamKey,
  onUserClicked,
  item,
}: {
  teamKey: string
  onUserClicked: (userKey: string | null) => void
  item: shared.com.probuildsoftware.probuild.library.timesheets.data.view.TimesheetUserListItemViewData
}) => {
  return (
    <ListItemButton
      key={item.userKey}
      divider={true}
      onClick={() => {
        onUserClicked(item.userKey)
      }}
    >
      <TableCellContent>
        <UserAvatar size={50} viewData={item.avatar || null} />
        <TextContainerDiv>
          <Box fontStyle={item.isMe ? "italic" : "normal"}>{item.name}</Box>
          <Typography color="textSecondary">{item.totalTime}</Typography>
        </TextContainerDiv>
      </TableCellContent>
    </ListItemButton>
  )
}

export default TimesheetListItemView
