import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import usePresenter from "model/presenter/usePresenter"

const useDownloadApp =
  (): shared.com.probuildsoftware.probuild.library.users.data.view.DownloadAppViewData | null => {
    const dependencyProvider = useDependencyProvider()
    const presenter = useMemo(() => {
      if (dependencyProvider) {
        console.log(`Creating download app presenter`)
        return new shared.com.probuildsoftware.probuild.library.users.DownloadAppPresenter(
          dependencyProvider
        )
      }
    }, [dependencyProvider])
    const [viewData] = usePresenter(presenter)
    return viewData
  }

export default useDownloadApp
