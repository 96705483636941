import { styled, SxProps } from "@mui/material/styles"
import usePlatform, { Platform } from "model/utils/usePlatform"
import useDownloadApp from "model/users/invite/useDownloadApp"

const ContainerDiv = styled("div")({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

const DownloadAnchor = styled("a")({
  width: "250px",
  maxWidth: "60vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

const DownloadAnchorContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  margin: theme.spacing(4),
}))

const AppDownloadBadges = () => {
  const platform = usePlatform()
  return (
    <ContainerDiv>
      {platform === "Android" ? (
        <DownloadAnchorContainer>
          <DownloadBadge platform="Android" />
          <DownloadBadge platform="iOS" />
        </DownloadAnchorContainer>
      ) : (
        <DownloadAnchorContainer>
          <DownloadBadge platform="iOS" />
          <DownloadBadge platform="Android" />
        </DownloadAnchorContainer>
      )}
    </ContainerDiv>
  )
}

const DownloadBadge = ({
  platform,
  sx,
}: {
  platform: Platform
  sx?: SxProps
}) => {
  const downloadApp = useDownloadApp()
  if (downloadApp) {
    return (
      <DownloadAnchor
        sx={sx}
        href={
          platform === "iOS"
            ? downloadApp.iOSDownloadUrl
            : downloadApp.androidDownloadUrl
        }
      >
        <img
          style={{ width: "100%" }}
          src={
            platform === "iOS"
              ? "/appStoreDownloadBadge.svg"
              : "/playStoreDownloadBadge.svg"
          }
          alt={
            platform === "iOS"
              ? downloadApp.iOSDownloadDescription
              : downloadApp.androidDownloadDescription
          }
        />
      </DownloadAnchor>
    )
  } else {
    return null
  }
}

export default AppDownloadBadges
