import { styled } from "@mui/material/styles"
import { TableRow, TableCell, Avatar, IconButton } from "@mui/material"
import WebIcon from "@mui/icons-material/Web"
import WebAssetIcon from "@mui/icons-material/WebAsset"
import { Link } from "react-router-dom"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"

const TeamRowDiv = styled("div")({
  display: "flex",
  alignItems: "center",
})

const TeamNameDiv = styled("div")({
  flexGrow: 1,
  paddingLeft: 16,
})

const TeamSelectorItemView = ({
  item,
}: {
  item?: shared.com.probuildsoftware.probuild.library.team.data.view.TeamSelectorItemViewData
}) => {
  const teamLogoPath = item?.logo?.info.path
    ? `/api/storage/${item?.logo?.info.path}?resize=200x200&jpegQuality=80&rotate=true`
    : "/buildingIcon.png"
  const oldWebportalPath = `https://webportal-dot-${process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID}.appspot.com/webportal/teams/${item?.teamKey}`
  const newWebportalPath = (item?.teamKey && paths.team(item?.teamKey)) || ""
  return (
    <TableRow hover={true}>
      <TableCell>
        <TeamRowDiv>
          <div>
            <Avatar variant="rounded" src={teamLogoPath}></Avatar>
          </div>
          <TeamNameDiv>{item?.teamName || ""}</TeamNameDiv>
        </TeamRowDiv>
      </TableCell>
      <TableCell>{item?.userName || ""}</TableCell>
      <TableCell align="right">{item?.lastOnline || ""}</TableCell>
      <TableCell align="center">
        <a href={oldWebportalPath}>
          <IconButton size="large">
            <WebAssetIcon />
          </IconButton>
        </a>
      </TableCell>
      <TableCell align="center">
        <IconButton component={Link} to={newWebportalPath} size="large">
          <WebIcon />
        </IconButton>
      </TableCell>
      <TableCell align="center">{item?.deletedAt}</TableCell>
    </TableRow>
  )
}

export default TeamSelectorItemView
