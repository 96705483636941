import { Paper, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import ListDialogContent from "components/dialogs/ListDialogContent"
import useTimesheetUserList from "model/timesheets/useTimesheetsUserList"
import TimesheetListItemView from "components/views/timesheets/TimesheetListItemView"

const TotalTimePaper = styled(Paper)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
}))

const TimesheetUserListDialog = ({
  teamKey,
  projectKey,
  isOpen,
  onUserClicked,
}: {
  teamKey: string
  projectKey: string | null
  isOpen: boolean
  onUserClicked: (userKey: string | null) => void
}) => {
  const [viewData] = useTimesheetUserList({ teamKey, projectKey })
  const userListItems = viewData?.items?.asJsReadonlyArrayView()
  return (
    <NavigationBarDialog
      isOpen={isOpen}
      onClose={() => {
        onUserClicked(null)
      }}
      title={viewData?.title}
      barItems={<></>}
    >
      <ListDialogContent emptyViewData={null} emptyIcon={null}>
        <TotalTimePaper>
          <Typography>{viewData?.totalTimeLabel}</Typography>
          <Typography>{viewData?.totalTime}</Typography>
        </TotalTimePaper>
        {userListItems?.map((listItem) => {
          return (
            <TimesheetListItemView
              teamKey={teamKey}
              onUserClicked={() => {
                onUserClicked(listItem.userKey)
              }}
              item={listItem}
            />
          )
        })}
      </ListDialogContent>
    </NavigationBarDialog>
  )
}

export default TimesheetUserListDialog
