import styled from "@emotion/styled"
import { Routes, Route, Navigate, Outlet, useParams } from "react-router-dom"
import paths from "model/utils/paths"
import ClientEditPage from "components/pages/ClientEditPage"
import ClientListPage from "components/pages/ClientListPage"
import ContractEditPage from "components/pages/ContractEditPage"
import ContractListPage from "components/pages/ContractListPage"
import DocumentEditPage from "components/pages/DocumentEditPage"
import DocumentReportPage from "components/pages/DocumentReportPage"
import DocumentsListPage from "components/pages/DocumentsListPage"
import FeedPage from "components/pages/FeedPage"
import HelpAndFeedbackPage from "components/pages/HelpAndFeedbackPage"
import ItemEditPage from "components/pages/ItemEditPage"
import ItemListPage from "components/pages/ItemListPage"
import NotFoundPage from "components/pages/NotFoundPage"
import PdfSharedPage from "components/pages/PdfSharedPage"
import PhoneNumberChangeVerifyPage from "components/pages/PhoneNumberChangeVerifyPage"
import SubscriptionPage from "components/pages/SubscriptionPage"
import TeamHomePage from "components/pages/TeamHomePage"
import TeamProfileEditPage from "components/pages/TeamProfileEditPage"
import UserAddPage from "components/pages/UserAddPage"
import UserInvitePage from "components/pages/UserInvitePage"
import ProjectPreviewPage from "components/pages/ProjectPreviewPage"
import ProjectEditPage from "components/pages/ProjectEditPage"
import PostPreviewPage from "components/pages/PostPreviewPage"
import ProjectMemberListPage from "components/pages/ProjectMemberListPage"
import UserListPage from "components/pages/UserListPage"
import UserProfilePage from "components/pages/UserProfilePage"
import NavigationDrawer from "components/views/app/NavigationDrawer"
import ImpersonationBar from "components/views/app/ImpersonationBar"
import NavigationDrawerProvider from "contexts/providers/NavigationDrawerProvider"
import useAccountsList from "model/app/useAccountsList"
import ProjectListPage from "components/pages/ProjectListPage"
import TimesheetUserListPage from "components/pages/TimesheetUserListPage"

const HorizontalStackDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  overflow: "hidden",
  height: "100%",
})

const VerticalStackDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
})

const TeamRoutes = () => {
  const { teamKey } = useParams()
  if (!teamKey) {
    return <Navigate to={"/"} replace={true} />
  }
  return (
    <Routes>
      <Route
        path="pdf/:sharedId"
        element={<PdfSharedPage teamKey={teamKey} />}
      />
      <Route
        path="phone-number-change-verify/:requestUuid"
        element={<PhoneNumberChangeVerifyPage teamKey={teamKey} />}
      />
      <Route path="*" element={<TeamAuthenticatedRootRoute />}>
        <Route index element={<TeamHomePage teamKey={teamKey} />} />
        <Route path="clients" element={<ClientListPage teamKey={teamKey} />} />
        <Route
          path="clients/:clientKey/edit"
          element={<ClientEditPage teamKey={teamKey} />}
        />
        <Route
          path="clients/create"
          element={<ClientEditPage teamKey={teamKey} />}
        />
        <Route
          path="contracts"
          element={<ContractListPage teamKey={teamKey} />}
        />
        <Route
          path="contracts/:contractKey/edit"
          element={<ContractEditPage teamKey={teamKey} />}
        />
        <Route
          path="contracts/create"
          element={<ContractEditPage teamKey={teamKey} />}
        />
        <Route
          path="documents"
          element={<DocumentsListPage teamKey={teamKey} />}
        />
        <Route
          path="documents/:documentKey"
          element={<DocumentReportPage teamKey={teamKey} />}
        />
        <Route
          path="documents/:documentKey/edit"
          element={<DocumentEditPage teamKey={teamKey} />}
        />
        <Route
          path="documents/create/:documentType"
          element={<DocumentEditPage teamKey={teamKey} />}
        />
        <Route path="feed" element={<FeedPage teamKey={teamKey} />} />
        <Route
          path="projects"
          element={<ProjectListPage teamKey={teamKey} />}
        />
        <Route
          path="projects/create"
          element={<ProjectEditPage teamKey={teamKey} />}
        />
        <Route
          path="projects/:projectKey"
          element={<ProjectPreviewPage teamKey={teamKey} />}
        />
        <Route
          path="projects/:projectKey/info"
          element={<ProjectEditPage teamKey={teamKey} />}
        />
        <Route
          path="projects/:projectKey/members"
          element={<ProjectMemberListPage teamKey={teamKey} />}
        />
        <Route
          path="posts/:projectKey/:postKey"
          element={<PostPreviewPage teamKey={teamKey} />}
        />
        <Route
          path="timesheets"
          element={<TimesheetUserListPage teamKey={teamKey} />}
        />
        <Route
          path="help"
          element={<HelpAndFeedbackPage teamKey={teamKey} />}
        />
        <Route path="items" element={<ItemListPage teamKey={teamKey} />} />
        <Route
          path="items/create"
          element={<ItemEditPage teamKey={teamKey} />}
        />
        <Route
          path="items/:itemKey/edit"
          element={<ItemEditPage teamKey={teamKey} />}
        />
        <Route path="users" element={<UserListPage teamKey={teamKey} />} />
        <Route
          path="profile"
          element={<TeamProfileEditPage teamKey={teamKey} />}
        />
        <Route path="users/add" element={<UserAddPage teamKey={teamKey} />} />
        <Route
          path="users/:userKey"
          element={<UserProfilePage teamKey={teamKey} />}
        />
        <Route
          path="users/:userKey/:inviteType"
          element={<UserInvitePage teamKey={teamKey} />}
        />
        <Route
          path="subscriptions"
          element={<SubscriptionPage teamKey={teamKey} />}
        />
        <Route path="*" element={<NotFoundPage teamKey={teamKey} />} />
      </Route>
    </Routes>
  )
}

const TeamAuthenticatedRootRoute = () => {
  const [accounts] = useAccountsList()
  const navigateToTeamKey = accounts?.navigateToTeamKey
  const currentAccountTeamKey = accounts?.currentAccount?.teamKey
  if (!accounts) {
    return <></>
  } else if (navigateToTeamKey) {
    return <Navigate to={paths.team(navigateToTeamKey)} replace={true} />
  } else if (accounts.isSignedOut || !currentAccountTeamKey) {
    return <Navigate to={paths.authPhone()} replace={true} />
  } else {
    return (
      <NavigationDrawerProvider teamKey={currentAccountTeamKey}>
        <VerticalStackDiv>
          <ImpersonationBar />
          <HorizontalStackDiv>
            <NavigationDrawer teamKey={currentAccountTeamKey} />
            <Outlet />
          </HorizontalStackDiv>
        </VerticalStackDiv>
      </NavigationDrawerProvider>
    )
  }
}

export default TeamRoutes
