import { useEffect, useMemo } from "react"
import { styled } from "@mui/material/styles"
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material"

import * as shared from "probuild-shared"

import SupportAgentPageLayout from "components/views/layouts/SupportAgentPageLayout"
import LoadingView from "components/views/LoadingView"
import TeamSelectorItemView from "components/views/team/TeamSelectorItemView"
import ErrorView from "components/views/ErrorView"
import EmptyView from "components/views/EmptyView"
import PageHeader from "components/views/generic/PageHeader"
import usePresenter from "model/presenter/usePresenter"
import useDependencyProvider from "model/utils/useDependencyProvider"

const PaddedDiv = styled("div")({
  paddingTop: 16,
})

const SupportAgentTeamSelectorPage = () => {
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider) {
      return new shared.com.probuildsoftware.probuild.library.team.TeamSelectorPresenter(
        dependencyProvider
      )
    }
  }, [dependencyProvider])
  const phoneNumber = new URLSearchParams(window.location.search).get(
    "phoneNumber"
  )
  useEffect(() => {
    if (phoneNumber) {
      presenter?.input.loadTeams(phoneNumber)
    }
  }, [phoneNumber, presenter?.input])
  const [viewData] = usePresenter(presenter)
  return (
    <SupportAgentPageLayout>
      <PaddedDiv>
        <PageHeader title={viewData?.title} subtitle={viewData?.subtitle} />
        <TableContainer component={Paper}>
          <Table>
            <colgroup>
              <col width="30%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>{viewData?.teamNameLabel}</TableCell>
                <TableCell>{viewData?.userNameLabel}</TableCell>
                <TableCell align="right">{viewData?.lastOnlineLabel}</TableCell>
                <TableCell align="center">
                  {viewData?.oldWebportalLinkLabel}
                </TableCell>
                <TableCell align="center">
                  {viewData?.newWebportalLinkLabel}
                </TableCell>
                <TableCell align="center">Deleted At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewData?.items?.asJsReadonlyArrayView()?.map((item) => {
                return <TeamSelectorItemView key={item.viewKey} item={item} />
              })}
            </TableBody>
          </Table>
          <EmptyView icon={null} viewData={viewData?.empty} />
          {viewData?.status.isErrored === true && (
            <ErrorView description={viewData?.errorDescription || null} />
          )}
          {!viewData || !viewData.status.isLoaded ? <LoadingView /> : <div />}
        </TableContainer>
      </PaddedDiv>
    </SupportAgentPageLayout>
  )
}

export default SupportAgentTeamSelectorPage
